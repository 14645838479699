import { createWebHistory, createRouter } from 'vue-router';
import { useAuthStore } from '@/state/pinia';
import routes from './routes';
import appConfig from '../app.config.json';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

function hasAccess(routeSlug, routeGroup, userPermissions) {
  const allowedSlugs = userPermissions.map(f => f.droit_fenetre.slug_fenetre);
  //const allowedGroups = userPermissions.map(f => f.droit_fenetre.groupe);

  if (allowedSlugs.includes(routeSlug) || allowedSlugs.includes(routeGroup) || routeSlug == "*") {
    return true;
  }
 
  return false;
}

router.beforeEach(async (routeTo, routeFrom, next) => {
  const auth = useAuthStore();
  const authRequired = routeTo.matched.some(route => route.meta.authRequired);

  if (!authRequired) return next();

  if (auth.loggedIn) {
    const validUser = await auth.validate();
    if (validUser) {
      const user_info = auth.user;
      const userPermissions = user_info ? user_info.menu : [];
      const routeSlug = routeTo.meta.slug_fenetre;
      const routeGroup = routeTo.meta.groupe;
      //console.log(routeSlug)
      if (routeSlug && hasAccess(routeSlug, routeGroup, userPermissions)) {
        return next();
      } else {
        return next({ name: '404' });
      }
    } else {
      return redirectToLogin();
    }
  } else {
    return redirectToLogin();
  }

  function redirectToLogin() {
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    return;
  }

  document.title = routeTo.meta.title + ' | ' + appConfig.title;
  next();
});

export default router;
