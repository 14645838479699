import { useAuthStore } from "@/state/pinia";
export default [
  {
    path: "/",
    name: "dashboard-servers",
    meta: { title: "Dashboard", authRequired: true, slug_fenetre: 'dashboard-servers', groupe: 'dashboard' },
    component: () => import("../views/dashboards/servers")
  },

  {
    path: "/dashboard/stats",
    name: "dashboard-stats",
    meta: { title: "Dashboard", authRequired: true, slug_fenetre: 'dashboard-stats', groupe: 'dashboard' },
    component: () => import("../views/dashboards/stats")
  },

  {
    path: "/dashboard/charts",
    name: "dashboard-charts",
    meta: { title: "Dashboard", authRequired: true, slug_fenetre: 'dashboard-charts', groupe: 'dashboard' },
    component: () => import("../views/dashboards/charts")
  },


  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login"),
    meta: {
      title: "Login",
      slug_fenetre: '*',
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },  
  {
    path: "/404",
    name: "404",
    meta: { title: "404 Error Page", slug_fenetre: '*', },
    component: require("../views/utility/404").default
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
  {
    path: "/dashboard/clients",
    name: "clients",
    meta: { title: "Clients", authRequired: true, slug_fenetre: 'clients' },
    component: () => import("../views/clients/index")
  },

  {
    path: "/dashboard/clients/historique-transaction/:id",
    name: "historique-transaction",
    meta: { title: "Clients - Historiques des transactions", authRequired: true, slug_fenetre: 'historique-transaction' , groupe: 'clients'},
    component: () => import("../views/clients/historique_transactions")
  },
  {
    path: "/dashboard/clients/historique-commission/:id",
    name: "historique-commission",
    meta: { title: "Clients - Historiques des commissions", authRequired: true, slug_fenetre: 'historique-commission' , groupe: 'clients'},
    component: () => import("../views/clients/historique_commissions")
  },
  {
    path: "/dashboard/partenaires",
    name: "partenaires",
    meta: { title: "Partenaires", authRequired: true, slug_fenetre: 'partenaires' },
    component: () => import("../views/partenaires/index")
  },
  {
    path: "/operations/transactions",
    name: "transactions",
    meta: { title: "Transactions", authRequired: true, slug_fenetre: 'transactions', groupe: 'operations' },
    component: () => import("../views/operations/transactions")
  },
  {
    path: "/operations/transactions-pending",
    name: "transactions-pending",
    meta: { title: "Transactions En Cours", authRequired: true, slug_fenetre: 'transactions-pending', groupe: 'operations' },
    component: () => import("../views/operations/transactions_pending")
  },
  {
    path: "/operations/pm-transactions",
    name: "pm-transactions",
    meta: { title: "PM Transactions", authRequired: true, slug_fenetre: 'pm-transactions', groupe: 'operations' },
    component: () => import("../views/operations/pm_transactions")
  },
  {
    path: "/operations/tarifs",
    name: "tarifs",
    meta: { title: "Tarifications", authRequired: true, slug_fenetre: 'tarifs' , groupe: 'operations'},
    component: () => import("../views/operations/tarifs")
  },

  {
    path: "/operations/wave-transactions",
    name: "wave-transactions",
    meta: { title: "Wave Transactions", authRequired: true, slug_fenetre: 'wave-transactions', groupe: 'operations' },
    component: () => import("../views/operations/wave_transactions")
  },
  {
    path: "/operations/crypto-deposits",
    name: "crypto-deposits",
    meta: { title: "Crypto deposits", authRequired: true, slug_fenetre: 'crypto-deposits', groupe: 'operations' },
    component: () => import("../views/operations/crypto_deposits")
  },
  {
    path: "/operations/commissions",
    name: "commissions",
    meta: { title: "Commissions", authRequired: true, slug_fenetre: 'commissions', groupe: 'operations' },
    component: () => import("../views/operations/commissions")
  },
  {
    path: "/serveurs/liste",
    name: "liste-server",
    meta: { title: "Liste serveur", authRequired: true, slug_fenetre: 'liste-server' , groupe: 'serveurs'},
    component: () => import("../views/servers/liste_server")
  },
  {
    path: "/serveurs/server-param",
    name: "server-param",
    meta: { title: "Serveur Param", authRequired: true, slug_fenetre: 'server-param', groupe: 'serveurs' },
    component: () => import("../views/servers/server_param")
  },
  {
    path: "/serveurs/sms-serveur/:numero?",
    name: "sms-server",
    meta: { title: "SMS Serveur", authRequired: true, slug_fenetre: 'sms-server', groupe: 'serveurs' },
    component: () => import("../views/servers/sms_server")
  },
  {
    path: "/serveurs/serveur-operation/:code_server?",
    name: "serveur-operation",
    meta: { title: "Serveur operation", authRequired: true, slug_fenetre: 'serveur-operation' , groupe: 'serveurs'},
    component: () => import("../views/servers/server_operation")
  },
  {
    path: "/serveurs/serveur-liste-operation/:code_server?/:code_operation?",
    name: "serveur-liste-operation",
    meta: { title: "Serveur liste operation", authRequired: true, slug_fenetre: 'serveur-liste-operation' , groupe: 'serveurs'},
    component: () => import("../views/servers/server_liste_operation")
  },
  {
    path: "/serveurs/serveur-blacklist/:pays?/:currencie?",
    name: "serveur-blacklist",
    meta: { title: "Serveur blacklisté", authRequired: true, slug_fenetre: 'serveur-blacklist' , groupe: 'serveurs'},
    component: () => import("../views/servers/server_blacklist")
  },
  {
    path: "/finances/bilan",
    name: "finance-bilan",
    meta: { title: "Finances dashboard", authRequired: true, slug_fenetre: 'finance-bilan' , groupe: 'finances'},
    component: () => import("../views/finances/finance_bilan")
  },
  {
    path: "/finances/recettes",
    name: "finance-recette",
    meta: { title: "Finances recettes", authRequired: true, slug_fenetre: 'finance-recette' , groupe: 'finances'},
    component: () => import("../views/finances/finance_recette")
  },
  {
    path: "/finances/depenses",
    name: "finance-depense",
    meta: { title: "Finances depenses", authRequired: true, slug_fenetre: 'finance-depense' , groupe: 'finances'},
    component: () => import("../views/finances/finance_depense")
  },
  {
    path: "/finances/commissions",
    name: "finance-commission",
    meta: { title: "Finances commissions", authRequired: true, slug_fenetre: 'finance-commission' , groupe: 'finances'},
    component: () => import("../views/finances/finance_commission")
  },
  {
    path: "/finances/operations/transfert-reserves",
    name: "finance-transfert",
    meta: { title: "Finances operations transfert reserves", authRequired: true, slug_fenetre: 'finance-transfert', groupe: 'finances' },
    component: () => import("../views/finances/reserves/operation_reserve")
  },
  {
    path: "/finances/operations/echange-reserves",
    name: "finance-echange",
    meta: { title: "Finances operations echange reserves", authRequired: true, slug_fenetre: 'finance-echange' , groupe: 'finances'},
    component: () => import("../views/finances/reserves/operation_echange")
  },
  {
    path: "/finances/operations/recharge-reserves",
    name: "finance-recharge",
    meta: { title: "Finances operations recharge reserves", authRequired: true, slug_fenetre: 'finance-recharge' , groupe: 'finances'},
    component: () => import("../views/finances/reserves/operation_recharge")
  },
  {
    path: "/parametres/monnaies",
    name: "parametre-monnaie",
    meta: { title: "Paramètres - Monnaies", authRequired: true, slug_fenetre: 'parametre-monnaie' , groupe: 'parametres'},
    component: () => import("../views/monnaies/index")
  },
  {
    path: "/parametres/pays",
    name: "parametre-pays",
    meta: { title: "Paramètres - Pays", authRequired: true, slug_fenetre: 'parametre-pays' , groupe: 'parametres'},
    component: () => import("../views/pays/index")
  },
  {
    path: "/parametres/pays-service",
    name: "parametre-pays-service",
    meta: { title: "Paramètres - Pays Service", authRequired: true, slug_fenetre: 'parametre-pays-service' , groupe: 'parametres'},
    component: () => import("../views/pays/service")
  },
  {
    path: "/parametres/compte-admin",
    name: "parametre-compte-admin",
    meta: { title: "Paramètres - Compte Admin", authRequired: true, slug_fenetre: 'parametre-compte-admin' , groupe: 'parametres'},
    component: () => import("../views/compte-admin/index")
  },
  {
    path: "/parametres/compte-admin/security-fenetre/:compte_id",
    name: "parametre-droit-fenetre",
    meta: { title: "Paramètres - Compte fenetre", authRequired: true, slug_fenetre: 'parametre-droit-fenetre' , groupe: 'parametres'},
    component: () => import("../views/compte-admin/droits-access/droit-fenetre")
  },
  {
    path: "/parametres/compte-admin/security-button/:compte_id",
    name: "parametre-droit-button",
    meta: { title: "Paramètres - Compte bouton", authRequired: true, slug_fenetre: 'parametre-droit-button' , groupe: 'parametres'},
    component: () => import("../views/compte-admin/droits-access/droit-button")
  },
  {
    path: "/parametres/messages",
    name: "parametre-message",
    meta: { title: "Paramètres - Messages", authRequired: true, slug_fenetre: 'parametre-message' , groupe: 'parametres'},
    component: () => import("../views/messages/index")
  },
  {
    path: "/parametres/service-client",
    name: "parametre-service-client",
    meta: { title: "Paramètres - Service Client", authRequired: true, slug_fenetre: 'parametre-service-client', groupe: 'parametres' },
    component: () => import("../views/service-client/index")
  },
  {
    path: "/parametres/ligne-service-client",
    name: "parametre-ligne-service-client",
    meta: { title: "Paramètres - Ligne Service Client", authRequired: true, slug_fenetre: 'parametre-ligne-service-client' , groupe: 'parametres'},
    component: () => import("../views/ligne-service-client/index")
  },
  {
    path: "/parametres/fenetres",
    name: "parametre-fenetre",
    meta: { title: "Paramètres - Fenêtre", authRequired: true, slug_fenetre: 'parametre-fenetre' , groupe: 'parametres'},
    component: () => import("../views/fenetres/index")
  },
  {
    path: "/parametres/fenetres/boutons",
    name: "parametre-bouton",
    meta: { title: "Paramètres - Boutons de la fenêtre", authRequired: true, slug_fenetre: 'parametre-bouton' , groupe: 'parametres'},
    component: () => import("../views/fenetres/bouton")
  },
];
