import Swal from "sweetalert2";
//import router from "../../../router";
//import { useAuthStore } from "../../../state/pinia";
import axios from "axios";

const axiosServices = axios.create({
  baseURL: "https://backoffice-two-api.meta-change.io/api",
  headers: {
    'Access-Control-Allow-Origin': "*",
    'Content-Type': 'application/json;charset=UTF-8',
  }
});



const app_url = "https://apiservice.meta-change.io/";
export { app_url };

// Créez une variable pour suivre l'état du chargement
let isLoading = false;

//créer un intercepteur
axiosServices.interceptors.request.use((config) => {
  // Vérifiez si le chargement est déjà en cours
  if (!isLoading) {
    // Afficher le loader
    Swal.fire({
      position: "center",
      html: '<div class="is-loading" style="position: relative;height: 150px;"><div class="preloader-component" style="position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 999;width: 100%;">' +
        '<div class="status" style="width: 40px;height: 40px;position: absolute;left: 50%;top: 50%;margin: -20px 0 0 -20px;">' +
        '<div class="spinner-chase">' +
        '<div class="chase-dot"></div>' +
        '<div class="chase-dot"></div>' +
        '<div class="chase-dot"></div>' +
        '<div class="chase-dot"></div>' +
        '<div class="chase-dot"></div>' +
        '<div class="chase-dot"></div>' +
        '</div>' +
        '</div>' +
        '</div></div>',
      showConfirmButton: false,
      //closeOnClickOutside: false,
      //background: '#555555'
    });
    // Mettre à jour l'état du chargement
    isLoading = true;
  }

  return config;

});

// interceptor for http
axiosServices.interceptors.response.use((response) => {
  // Masquer le loader
      setTimeout(() => {
        Swal.close();
        // Mettre à jour l'état du chargement
        isLoading = false;
      }, 2500)
  return response;
},
  (error) => {
    if (error.response && error.response.status == 401) {
      // Masquer le loader en cas d'erreur
      Swal.close();
      // Mettre à jour l'état du chargement
      isLoading = false;
      localStorage.removeItem('wp_metachange');

      //const authStore = useAuthStore();
      // remove token and user info
      //authStore.ressertStore();
      // redirect to login page
      window.location.href = "/login"
      //router.push({ name: 'login' })
      //Promise.reject("Wrong Services")

    }
    // Masquer le loader en cas d'erreur
    Swal.close();
    // Mettre à jour l'état du chargement
    isLoading = false;
    //Promise.reject((error.response && error.response.data) || "Wrong Services")

  }
);


export default axiosServices;
