import CryptoJS from 'crypto-js';
import router from '@/router'
import Swal from 'sweetalert2';
import $ from "jquery"

import { useAuthStore } from '@/state/pinia'

export default function helpers() {
    const decrypt = (encryptedData) => {
        let decrypt = CryptoJS.AES.decrypt(encryptedData, 'metachange2024');
        return decrypt.toString(CryptoJS.enc.Utf8);
    }

    const encrypt = (to_be_encrypted) => {
        return CryptoJS.AES.encrypt(to_be_encrypted, "metachange2024").toString()
    }

    // Fonction utilitaire pour obtenir le chemin par nom de route
    const getPathByName = (name) => {
        const route = router.resolve({ name })
        return route.href
    };

    const afficherMessagesSuccess = (param) => {
        return Swal.fire({
            icon: "success",
            title: param,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            background: "green",
            color: "white",
            iconColor: "white",
        });
    }


    const setDataTable = () => {
        // initialize datatable
        $(document).ready(function () {
            $('.data-table').DataTable({
                "ordering": true,
                "language": {
                    "sProcessing": "Traitement en cours ...",
                    //"sLengthMenu": "Afficher _MENU_ lignes",
                    "sZeroRecords": "Aucun résultat trouvé",
                    "sEmptyTable": "Aucune donnée disponible",
                    "sLengthMenu": "Afficher &nbsp; _MENU_ &nbsp;",
                    "sInfo": "_START_ ... _END_/_TOTAL_ &eacute;l&eacute;ments",
                    "sInfoEmpty": "Aucune ligne affichée",
                    "sInfoFiltered": "(Filtrer un maximum de _MAX_)",
                    "sInfoPostFix": "",
                    "sSearch": "Recherche",
                    "sUrl": "",
                    "sInfoThousands": ",",
                    "sLoadingRecords": "Chargement...",
                    "oPaginate": {
                        "sFirst": "Premier",
                        "sLast": "Dernier",
                        "sNext": "Suivant",
                        "sPrevious": "Précédent"
                    },
                    "oAria": {
                        "sSortAscending": ": Trier par ordre croissant",
                        "sSortDescending": ": Trier par ordre décroissant"
                    }

                },
                dom: '<"float-left"l><"float-right"f>Brti<"float-right"p>',
                //   stateSave : true,
                order: [
                    [0, "asc"]
                ],
                processing: true,
                serverSide: false,
                retrieve: true,
                //paging: false
            });

        });
    }

    const afficherOneMessageError = (param) => {
        return Swal.fire({
            icon: "error",
            title: param,
        });
    }

    const formaterDate = (date) => {
        const year = date.getFullYear();
        const month = padZero(date.getMonth() + 1);
        const day = padZero(date.getDate());
        return `${year}-${month}-${day}`;
    }

    const padZero = (value) => {
        return value < 10 ? '0' + value : value;
    }


    const getUserButton = (fenetre, button) => {
        const authStore = useAuthStore();

        const auth = authStore.user;
        const user_info = auth
        const userButton = user_info ? user_info.button : [];

        //const allowedLibelle = userButton.map(f => f.droit_button?.libelle_button);
        //const allowedFenetre = userButton.map(f => f.droit_button?.fenetre);
        const allowedOptions = userButton.flatMap(f => f.droit_button);

        // Vérifiez si l'utilisateur a le droit pour le bouton et la fenêtre donnés
        const hasAccess = allowedOptions.some(option =>
            (option.libelle_button === button && option.fenetre === fenetre) || button === "*"
        );

        return hasAccess;

    }


    return {
        decrypt,
        encrypt,
        getPathByName,
        afficherMessagesSuccess,
        afficherOneMessageError,
        setDataTable,
        formaterDate,
        getUserButton
    }
}