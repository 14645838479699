import { createApp } from 'vue';
require('datatables.net-bs5');

import App from './App.vue';
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';
//import "./assets/css/style.css"

import { vMaska } from "maska";
import i18n from "./i18n";

import BootstrapVueNext from 'bootstrap-vue-next';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css";

// PINIA
import pinia from '@/state/pinia';

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

const app = createApp(App);

// Ajouter une propriété globale pour stocker le nom de la route précédente
app.config.globalProperties.$previousRouteName = null;

// Garde de navigation globale
router.beforeEach((to, from, next) => {
    app.config.globalProperties.$previousRouteName = from.name;
    next();
});

app.use(pinia)
    .use(router)
    .use(require('vue-chartist'))
    .use(BootstrapVueNext)
    .use(VueApexCharts)
    .use(vClickOutside)
    .use(i18n)
    .use(registerScrollSpy)
    .directive("maska", vMaska)
    .mount('#app');
