import { defineStore } from "pinia";
import helpers from "../../views/utility/helpers/helpers";
import axiosServices from "../../views/utility/helpers/axios";
import router from "../../router";
import CryptoJS from 'crypto-js';

const initStore = () => {
    return {
        currentUser: {},
        wp: null,
    };
}

let stored_data = initStore();
if (localStorage.getItem('wp_metachange')) {
    const { decrypt } = helpers();
    let decrypt_data = decrypt(localStorage.getItem('wp_metachange'))

    stored_data = JSON.parse(decrypt_data)
    axiosServices.defaults.headers.common['Authorization'] = `Bearer ${stored_data.wp}`
}

export const useAuthStore = defineStore("auth", {
    state: () => ({
        user: stored_data.user,
        wp: stored_data.wp,
        returnUrl: null,
        axiosServices
    }),
    getters: {
        loggedIn() {
            return !!this.user
        },
    },
    actions: {
        async login(email, password) {
            let store = this;
            return await new Promise((resolve, reject) => {
                axiosServices
                    .post(`/auth/login`, { email, password })
                    .then((response) => {
                        if (response.status == 200) {
                            store.axiosServices.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                            let user_datas = {};
                            user_datas.user = response.data;
                            user_datas.wp = response.data.token;
                            let wp_metachange = CryptoJS.AES.encrypt(JSON.stringify(user_datas), "metachange2024").toString()

                            store.wp = response.data.token;
                            store.user = response.data;
                            localStorage.setItem('wp_metachange', wp_metachange);
                            router.push({ name: "dashboard-servers" });
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        },
        async logout() {

            //Deconnexion
            return await new Promise((resolve, reject) => {
                axiosServices
                    .post(`/auth/logout`)
                    .then((response) => {
                        if (response.status == 200) {

                            this.user = null;
                            localStorage.removeItem('wp_metachange');
                            this.$patch(initStore())
                            router.push({
                                name: 'login'
                            });
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })

        },
        validate() {
            if (!this.user) {
                return Promise.resolve({})
            }
            const user = this.user

            return Promise.resolve(user)

        },

        ressertStore() {
            this.user = null;
            localStorage.removeItem('wp_metachange');
            this.$patch(initStore())
            router.push({
                name: 'login'
            });
        },

    },
})

/*axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        if (
            error.message == "Le token est expiré"
        ) {
            let store = useAuthStore();
            store.logout();
        } else {
            return Promise.reject(error)
        }
    }
);*/

